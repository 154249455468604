<template>
  <v-app id="customerApp">
    <v-navigation-drawer
      width="280"
      :right="lang == 'ar'"
      app
      v-model="drawer"
      class="bg-secondary"
    >
      <h2 class="my-4 clr-primary text-center">{{ $t("V-shape") }}</h2>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item
          link
          to="/customer/home"
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("Home") }}</v-list-item-title>
        </v-list-item>

        <!-- <v-list-group
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-call-split</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("Resturant") }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            link
            to="/customer/subscriptions"
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("IngredientsMenu")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/recipie"
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Recipies")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            to="/admin/menu"
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("CusineMenu")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group
          class="noBackground"
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-arrow-up-bold-box-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("Sales") }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
           
            link
            to="/admin/subscribe?type=&customer_name=&customer_mobile=&city_id=&branch_id="
          >
            <v-list-item-content>
              <v-list-item-title class="mr-menu">{{
                $t("Subscriptions")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


        </v-list-group> -->

        <v-list-item
          link
          to="/customer/subscriptions"
        >
          <v-list-item-icon>
            <v-icon>mdi-dialpad</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("MySubscriptions") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      class="bg-secondary"
      :style="lang == 'ar' ? 'direction:rtl;' : 'direction:ltr;'"
    >
      <v-row>
        <v-col md="12">
          <div>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <div
              class="d-flex align-center"
              :class="lang == 'ar' ? 'float-left ml-3' : 'float-right mr-3'"
            >
              <v-menu top left dense>
                <template v-slot:activator="{ on, attrs }">
                  <img
                    style="cursor: pointer"
                    @click="changeLang('ar')"
                    width="40"
                    height="40"
                    v-if="lang == 'en'"
                    src="/assets/ar.png"
                    alt=""
                  />
                  <img
                    style="cursor: pointer"
                    @click="changeLang('en')"
                    width="40"
                    height="40"
                    v-else
                    src="/assets/en.png"
                    alt=""
                  />

                  <p v-bind="attrs" v-on="on" class="mx-3 mt-4 clr-primary">
                    {{ user.name }}
                  </p>

                </template>
                <v-list dense>
                  <v-list-item dense>
                    <v-btn small color="danger" @click="logout" text
                      >{{ $t("Logout") }}

                      <v-icon class="my-0 mr-7" dense>mdi-logout</v-icon>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app> </v-footer>
  </v-app>
</template>

<script>
import config from "@/config";
import mixin from '@/mixin';
export default {
  name: "App",
  data: () => ({
    lang: localStorage.getItem("lang") || "ar",
    drawer: true,
    config: config,
    user: localStorage.getItem("customer")
      ? JSON.parse(localStorage.getItem("customer"))
      : [],
  }),
  methods: {
    changeLang(lang) {
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
    goTo(page) {
      window.open(page);
    },
    logout() {
      this.$store
        .dispatch("auth/logoutCustomer", this.object)
        .then((result) => {
          this.notifySuccess("Logout", result);
          this.logoutCustomerSuccess();
        })
        .catch((err) => {
          this.logoutCustomerSuccess();
        });
    },
  },
  mounted() {
    try {
       let user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : []
    if(!user ||  !user.customer || user.customer.permissions.length == 0) {
      // mixin.methods.logoutSuccess();
    } 
    } catch (error) {
      //  mixin.methods.logoutSuccess();
    }
  }
};
</script>
